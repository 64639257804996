import React, { useEffect } from 'react';
import flatpickr from 'flatpickr';
import { German } from 'flatpickr/dist/l10n/de';
import { Spanish } from 'flatpickr/dist/l10n/es';
import { Russian } from 'flatpickr/dist/l10n/ru';
import { French } from 'flatpickr/dist/l10n/fr';
import { usePublisherMe } from '~/hooks/api/usePublisherMe';
import { usePublisherGetProfile } from '~/hooks/api/usePublisherGetProfile';
import { CustomLocale } from 'flatpickr/dist/types/locale';

export const flatPickerLocaleMap: Record<string, CustomLocale | undefined> = {
  de: German,
  es: Spanish,
  ru: Russian,
  fr: French,
  en: undefined, // uses en by default
};

type ConfigureFlatpickrLocaleProps = {
  children: React.ReactNode;
};

const ConfigureFlatpickrLocale = ({
  children,
}: ConfigureFlatpickrLocaleProps) => {
  const { data: publisher } = usePublisherMe();
  const { data: profile, isFetching } = usePublisherGetProfile(
    publisher?.id || 1,
    {
      enabled: !!publisher,
    }
  );
  useEffect(() => {
    if (!isFetching && profile) {
      const locale = flatPickerLocaleMap[profile?.uiLanguage || 'de'];
      flatpickr.localize(locale || German);
    }
  }, [profile]);

  return children;
};

export default ConfigureFlatpickrLocale;

import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import {
  emergencyModeGetPublisherEmergencyStatus,
  PublisherEmergencyReportResource,
} from '~/generated';

export const useEmergencyModePublisher = (
  publisherId: number,
  options: Omit<
    UseQueryOptions<PublisherEmergencyReportResource, unknown>,
    'queryKey'
  > = {}
) =>
  useQuery({
    queryFn: () => emergencyModeGetPublisherEmergencyStatus({ publisherId }),
    retry: false,
    ...options,
    queryKey: ['emergencyMode', 'publisher', publisherId],
  });

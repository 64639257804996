/**
 *
 * App.react.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React from 'react';
import { styled } from '@mui/material/styles';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useMediaQuery } from '@mui/material';
import { indigo, pink } from '@mui/material/colors';

import Snackbar from '~/containers/Snackbar';
import DrawerMenu from '~/containers/DrawerMenu';
import ConfigureFlatpickrLocale from '~/containers/ConfigureFlatpickrLocale';
import PageTitle from '~/containers/PageTitle';
import Routes from '~/routes';

import { deDE, enUS, esES, frFR, ruRU } from '@mui/material/locale';
import styles from './styles.module.css';
import { useUILanguage } from '../UILanguage';

const PREFIX = 'App';

const classes = {
  drawer: `${PREFIX}-drawer`,
};

const Root = styled('div')({
  height: '100%',
  [`& .${classes.drawer}`]: {
    width: 300,
  },
});

const muiLocaleMapping = {
  en: enUS,
  de: deDE,
  ru: ruRU,
  es: esES,
  fr: frFR,
};

export const App = () => {
  const { locale } = useUILanguage();
  const theme = createTheme(
    {
      palette: {
        primary: {
          main: indigo[500],
        },
        secondary: {
          main: pink[500],
        },
      },
    },
    muiLocaleMapping[locale]
  );
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  return (
    <Root>
      <CssBaseline />
      <PageTitle />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <div className={styles.container}>
            <ConfigureFlatpickrLocale>
              {!isLargeScreen ? <DrawerMenu /> : null}
              <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              />
            </ConfigureFlatpickrLocale>
            {isLargeScreen ? (
              <nav className={classes.drawer}>
                <DrawerMenu variant="permanent" forceOpen />
              </nav>
            ) : null}
            <div className={styles.children}>
              <Routes />
            </div>
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
    </Root>
  );
};

export default App;

import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PublisherProfileResource, publishersGetProfile } from '~/generated';

export const usePublisherGetProfile = (
  id: number,
  options: Omit<UseQueryOptions<PublisherProfileResource>, 'queryKey'> = {}
) =>
  useQuery({
    queryFn: () => publishersGetProfile({ id }),
    queryKey: ['publishers', 'profile', id],
    ...options,
  });

import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PublisherResource, publishersGetMe } from '~/generated';

export const usePublisherMe = (
  options: Omit<UseQueryOptions<PublisherResource, unknown>, 'queryKey'> = {}
) =>
  useQuery({
    queryFn: () => publishersGetMe(),
    queryKey: ['publishers', 'me'],
    ...options,
  });
